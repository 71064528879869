<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="vd_search_group">
        <el-form
          label-width="120px"
          :inline="true"
          size="min"
          :model="searchForm"
          ref="searchForm"
          @keyup.enter.native="getPodtsNow()"
        >
          <el-row>
            <el-col :md="8">
              <el-form-item label="产品编号:" >
                <el-input
                  size="small"
                  v-model.trim="searchForm.prod_no"
                  clearable
                  placeholder="请填写产品编号"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="产品订单编号:">
                <el-input
                  size="small"
                  v-model.trim="searchForm.podr_no"
                  clearable
                  placeholder="请填写产品订单编号"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="产品名称:">
                <el-input
                  size="small"
                  v-model.trim="searchForm.prod_name"
                  clearable
                  placeholder="请填写产品名称"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :md="8">
              <el-form-item label="单据状态:">
                <el-select size="small" v-model="searchForm.status" placeholder="请选择单据状态" clearable >
                  <el-option
                      v-for="item in statusList"
                      :key="item.id"
                      :label="item.label"
                      :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="制作状态:">
                <el-select size="small" v-model="searchForm.podr_prod_status" placeholder="请选择制作状态" clearable >
                  <el-option
                      v-for="item in operationList"
                      :key="item.id"
                      :label="item.label"
                      :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :md="14">
              <el-form-item label="创建时间:">
                <el-date-picker
                  size="small"
                  v-model="searchForm.timeValue"
                  type="datetimerange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item>
                <el-button
                  size="small"
                  type="primary"
                  icon="el-icon-search"
                  @click="getPodtsNow()"
                  class="vg_ml_16"
                  >查询</el-button
                >
                <el-button
                  type="info"
                  size="small"
                  class="vd_export"
                  @click="buttonRefresh()"
                  ><i class="el-icon-refresh-right"></i> 刷新</el-button
                >
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="vd_button_group vg_mtb_16">
        <!-- <el-button type="primary" size="small" @click="addRow()" :disabled="!btn.add"><i class="el-icon-plus"></i> 新增</el-button> -->
        <el-button
          type="danger"
          size="small"
          :disabled="!btn.delete"
          @click="doDelete()"
          ><i class="el-icon-delete"></i> 删除</el-button
        >
      </div>
      <el-row>
        <el-col :md="24">
          <el-table
            ref="multiTable"
            v-loading="loadingFlag"
            class="vg_cursor"
            :data="tableData"
            @selection-change="handleSelectionChange"
            @select="selectRows"
            @select-all="selectRows"
            @row-dblclick="dbClickJp"
            border
          >
            <el-table-column type="selection" width="48" align="center" />
            <el-table-column label="产品编号" prop="prod_no" />
            <el-table-column label="产品订单编号" prop="podr_no" />
            <el-table-column label="客户货号" prop="podr_prod_cust_no">
              <template slot-scope="scope">
                <span v-if="scope.row.podr_prod_cust_no">
                  {{ scope.row.podr_prod_cust_no }}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column
              label="产品名称"
              prop="prod_name"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <span v-if="scope.row.prod_name">
                  {{ scope.row.prod_name }}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column
              label="产品数量"
              show-overflow-tooltip
              :formatter="formatterNum"
            />
            <el-table-column
              label="录入人"
              prop="stff_name"
              show-overflow-tooltip
              :formatter="helper.personCombina"
            />
            <el-table-column
              label="创建时间"
              prop="create_time"
              :formatter="formatDate"
              show-overflow-tooltip
            />
            <el-table-column label="单据状态" align="center">
              <template slot-scope="scope">
                <el-tag
                  :type="helper.getStatusName(scope.row.status).type"
                  size="mini"
                  >{{ helper.getStatusName(scope.row.status).name }}</el-tag
                >
              </template>
            </el-table-column>
            <el-table-column label="制作状态" align="center">
              <template slot-scope="scope">
                <el-tag size="mini" v-if="scope.row.podr_prod_status == 0"
                  >未开卡</el-tag
                >
                <el-link
                  class="vg_cursor"
                  size="mini"
                  type="warning"
                  v-if="scope.row.podr_prod_status == 1"
                  @click="clickSucc(scope.row)"
                  >正在制造</el-link
                >
                <el-tag
                  size="mini"
                  type="success"
                  v-if="scope.row.podr_prod_status == 2"
                  >已完成</el-tag
                >
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="24">
          <pubPagination
            :totalPage="totalPage"
            @changePageSearch="changePageSearch"
            ref="pubPagination"
          ></pubPagination>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import { get, post } from "@api/request";
import { podtAPI } from "@api/modules/podt";
import pubPagination from "@/components/common/pubPagination";
// import selectStatus from "@/views/component/selectStatus";
import helper from "@assets/js/helper.js";
export default {
  name: "podtList",
  components: {
    pubPagination,
    // selectStatus,
  },
  data() {
    return {
      searchForm: {
        prod_no: null,
        podr_no: null,
        status:null,
        podr_prod_status:null,
        timeValue: {
          startTime: null,
          endTime: null,
        },
        prod_name: null,
      },
      currentPage: 1,
      totalPage: 0,
      tableData: [],
      tableData1: [],
      tableDataList: [],
      multiSelection: [],
      btn: {},
      statusList:[{
        id:0,label:'草拟'
      },{
        id:1,label:'在批'
      },{
        id:2,label:'生效'
      }],
      operationList:[
        {id:0,label:'未开卡'},
        {id:1,label:'正在制造'},
        {id:2,label:'已完成'}
      ],
      podtType: [
        { id: 1, label: "新款" },
        { id: 2, label: "老款" },
        { id: 3, label: "修改款" },
      ],
      loadingFlag: true,
    };
  },
  created() {
    this.initData();
  },
  watch: {
    $route(to, from) {
      if (from.path === "/podt_edit") {
        this.initData();
      }
    },
  },
  filters: {
    // 产品类型转换
    formatPodtType(row) {
      if (row === 1) {
        return "新款";
      } else if (row === 2) {
        return "老款";
      } else if (row === 3) {
        return "修改款";
      }
    },
    //交样时间
    formatDate1(row) {
      return helper.toStringDate(row);
    },
    formaUnitP(row) {
      return helper.haveFour(row);
    },
    // 物料单位转换
    formaUnitPr(row) {
      return helper.haveFour(row);
    },
  },
  methods: {
    initData() {
      this.loadingFlag = true;
      this.getPodts();
    },
    // 获取产品信息
    getPodts() {
      const timeNewVal = this.helper.getTime(this.searchForm.timeValue);
      get(podtAPI.getPodts, {
        prod_no: this.searchForm.prod_no,
        podr_no: this.searchForm.podr_no,
        page_no: this.currentPage,
        status:this.searchForm.status,
        podr_prod_status:this.searchForm.podr_prod_status,
        start_time: timeNewVal.startTime,
        end_time: timeNewVal.endTime,
        prod_name: this.searchForm.prod_name,
      })
        .then((res) => {
          if (res.data.code === 0) {
            this.tableData = res.data.data.list;
            this.totalPage = res.data.data.total;
            this.btn = res.data.data.btn;
            setTimeout(() => {
              this.loadingFlag = false;
            }, 500);
          } else {
            let mg = res.data.msg;
            let tp = "error";
            this.$message({ message: mg, type: tp });
          }
        })
        .catch((res) => {
          let mg = res.data.msg;
          let tp = "error";
          this.$message({ message: mg, type: tp });
        });
    },
    // 刷新
    buttonRefresh() {
      this.searchForm = {
        prod_no: null,
        podr_no: null,
        status:null,
        podr_prod_status:null,
        timeValue: {
          startTime: null,
          endTime: null,
        },
        prod_name: null,
      };
      this.loadingFlag = true;
      this.currentPage = 1;
      this.initData();
      this.$refs.pubPagination.currentPage = 1;
    },
    // 时间转换
    formatDate(row) {
      return this.helper.toStringDate(row.create_time);
    },
    // 查询方法
    getPodtsNow() {
      this.loadingFlag = true;
      this.$refs.pubPagination.currentPage = 1;
      this.currentPage = 1;
      this.initData();
    },
    // 分页查询
    changePageSearch(val) {
      this.currentPage = val;
      this.initData();
    },
    // 多选
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    selectRows(selection) {
      this.btn.delete = this.helper.isDelete(selection);
    },
    // 状态查询
    getStatusVal(val) {
      this.searchForm.status = val;
    },
    // 数量显示
    formatterNum(row) {
      return this.helper.haveFour(row.podr_prod_num);
    },
    //删除提示
    doDelete() {
      if (this.multiSelection.length === 0) {
        this.$message({ type: "warning", message: "请至少选择一条数据！" });
      } else {
        let temp = false;
        for (let i = 0; i < this.multiSelection.length; i++) {
          if (this.multiSelection[i].status === 2) {
            temp = true;
            break;
          }
        }
        if (temp) {
          this.$message({ type: "warning", message: "已生效的数据不可删除！" });
          this.$refs.multiTable.clearSelection();
        } else {
          this.mBox();
        }
      }
    },
    mBox() {
      this.$confirm("确定删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.delRow();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
          this.$refs.multiTable.clearSelection();
        });
    },
    // 删除
    delRow() {
      const ids = [];
      this.multiSelection.map((item) => {
        ids.push(item.podr_prod_id);
      });
      post(podtAPI.deletePodtByIds, { podr_prod_id_list: ids })
        .then((res) => {
          if (res.data.code === 0) {
            let mg = res.data.msg;
            let tp = "success";
            this.$message({ message: mg, type: tp });
            if (ids.length === this.tableData.length) {
              if (this.currentPage > 1) {
                this.currentPage = this.currentPage - 1;
                this.$refs.pubPagination.currentPage = this.currentPage;
              }
            }
            this.initData();
          } else {
            let mg = res.data.msg;
            let tp = "error";
            this.$message({ message: mg, type: tp });
            this.initData();
          }
        })
        .catch((res) => {
          let mg = res.data.msg;
          let tp = "error";
          this.$message({ message: mg, type: tp });
        });
    },
    // 双击row跳转到详情编辑
    dbClickJp(row) {
      const permId = this.$route.query.perm_id;
      this.jump("/podt_edit", { perm_id: permId, form_id: row.podr_prod_id });
    },
    clickSucc(row) {
      this.$confirm('是否确认已完成?', '提示', {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          post(podtAPI.rfid_finish, { podr_prod_id: row.podr_prod_id })
          .then((res) => {
            if (res.data.code !== 0) {
              return this.$message.error(res.data.msg);
            }
            this.initData();
          })
          .catch((res) => {
            return this.$message.error(res.data.msg);
          });
        }).catch(()=>{})
    },
  },
};
</script>

<style scoped lang="scss">
.vd_button_group {
  width: 100vw;
  position: relative;
}
.vd_txta {
  position: absolute;
  left: 35%;
}
::v-deep .vd_tenpd {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.vd_img {
  margin-top: 5px;
  width: 40px;
}
.vd_notpass {
  color: rgb(187, 187, 187);
}
.vd_pass {
  color: #188dff;
}
.vd_complate {
  color: #36ab60;
}
.vd_disable {
  color: #d81e06;
}
.vd_wei {
  color: #ff8511;
}
</style>